import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RecaptchaComponent } from 'ng-recaptcha';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { UserManagementService } from '@app/services/api/user-management.service';
import { ApiError } from '@app/model/error/apiError';
import { ErrorCodes } from '@app/model/error/errorCodes';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  @ViewChild('recaptchaComponent', { static: true }) recaptchaComponent: RecaptchaComponent;
  forgotPasswordForm: FormGroup;
  private captchaPassed = false;
  private captchaResponse: string;
  success = false;

  constructor(private userManagementService: UserManagementService,
    private router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService, private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.email
      ])),
      recaptcha: new FormControl(null, Validators.required)
    });
  }

  sendPasswordResetMail(form: FormGroup) {
    this.success = false;
    this.userManagementService.sendPasswordResetMail(form.value.email, this.captchaResponse).toPromise().then(() => {
      this.success = true;
      this.snackBar.open(this.translate.instant('login.forgot-password.mail-sent'), null, { panelClass: 'snackbar-success' });
 
    }, (error: HttpErrorResponse) => {
      console.log('got error', error);
      this.recaptchaComponent.reset();
      if (error.error) {
        const apiError: ApiError = error.error;
        if (apiError.errorCode === ErrorCodes.RECAPTCHA_VALIDATION_FAILED) {
          this.snackBar.open(this.translate.instant('login.forgot-password.error.recaptcha-validation-failed'), null, { panelClass: 'snackbar-error' });
          return;
        }
      }
    });
  }

  captchaResolved(response: string) {
    this.captchaPassed = true;
    this.captchaResponse = response;
  }

}

