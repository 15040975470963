<div class="container">

  <div class="features">
    <mat-card class="feature-card">
      <mat-card-header>
        <mat-card-title>Täglich neue Angebote!</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="device-wrapper">
          <div class="device" data-device="iPhoneX" data-orientation="portrait" data-color="black">
            <div class="screen">
              <div class="screen-img screen-newest"></div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="feature-card">
      <mat-card-header>
        <mat-card-title>Finde Angebote und spare Geld bei deinem Lebensmitteleinkauf!</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="device-wrapper">
          <div class="device" data-device="iPhoneX" data-orientation="portrait" data-color="black">
            <div class="screen">
              <div class="screen-img screen-search"></div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="feature-card">
      <mat-card-header>
        <mat-card-title>Erstelle & verwalte Einkaufslisten!</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="device-wrapper">
          <div class="device" data-device="iPhoneX" data-orientation="portrait" data-color="black">
            <div class="screen">
              <div class="screen-img screen-shopping-list"></div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="feature-card">
      <mat-card-header>
        <mat-card-title>Mach ein Foto von dem Produkt das du suchst und erhalte das beste Angebot!
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="device-wrapper">
          <div class="device" data-device="iPhoneX" data-orientation="portrait" data-color="black">
            <div class="screen">
              <div class="screen-img screen-vision-search"></div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <mat-card class="download" id="download">
    <mat-card-header>
      <mat-card-title>Jetzt App installieren!</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="download-badges">
        <img class="badge-img" src="assets/images/google-play-badge.png" alt="Google Play Store"
             (click)="onAndroidStoreBadgeClick()">
        <img class="badge-img" src="assets/images/app-store-badge.svg" alt="Apple App Store"
             (click)="onIOSStoreBadgeClick()">
      </div>
    </mat-card-content>
  </mat-card>
</div>
