import {Injectable} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {MatIconRegistry} from "@angular/material/icon";

@Injectable({
  providedIn: 'root'
})
export class IconsService {

  icons = [
    {name: 'facebook-logo', path: 'assets/icons/social/facebook-logo.svg'},
    {name: 'twitter-logo', path: 'assets/icons/social/twitter-logo.svg'},
    {name: 'chevron-right', path: 'assets/icons/chevron-right.svg'},
    {name: 'discount', path: 'assets/icons/sort-options/discount.svg'},
    {name: 'best-seller', path: 'assets/icons/sort-options/best-seller.svg'},
    {name: 'low-price-euro', path: 'assets/icons/sort-options/low-price-euro.svg'},
    {name: 'price-per-unit', path: 'assets/icons/sort-options/price-per-unit.svg'},
    {name: 'hot-price', path: 'assets/icons/hot-price.svg'}
  ]

  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {

  }

  public registerIcons() {
    this.icons.forEach(icon => {
      this.matIconRegistry.addSvgIcon(icon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path));
    });
  }
}
