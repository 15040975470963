<div class="content-container">
    <h2>Register</h2>
    <h3>Benutzer anlegen</h3>
    <div class="loading-spinner-box" *ngIf="loading">
        <mat-spinner class="loading-spinner"></mat-spinner>
    </div>
</div>

<form *ngIf="!loading" [formGroup]="registerForm" (ngSubmit)="register(registerForm)">
    <div class="register-container">
        <mat-form-field appearance="outline">
            <mat-label>{{'login.register.email.label' | translate}}</mat-label>
            <input matInput formControlName="email" name="email" type="email"
                placeholder="{{'login.register.email.placeholder' | translate}}">
            <mat-icon matSuffix>account_circle</mat-icon>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>
                {{'login.register.password.label' | translate}}
            </mat-label>
            <input matInput name="password" type="password" formControlName="password"
                placeholder="{{'login.register.password.placeholder' | translate}}">
            <mat-icon matSuffix>password</mat-icon>
        </mat-form-field>



        <div *ngIf="registerForm.controls['password'].value && registerForm.controls['password'].errors"
            class="register-container">

            <div>
                <mat-icon color="{{registerForm.controls['password'].errors['pattern'] ? 'danger' : 'success'}}">
                    {{registerForm.controls['password'].errors['pattern'] ? 'close' : 'checkmark'}}
                </mat-icon>
                <mat-label>
                    {{'login.register.password.validation.pattern' | translate}}
                </mat-label>
            </div>

            <div>
                <mat-icon color="{{registerForm.controls['password'].errors['minlength'] ? 'danger' : 'success'}}">
                    {{registerForm.controls['password'].errors['minlength'] ? 'close' : 'checkmark'}}
                </mat-icon>
                <mat-label>
                    {{'login.register.password.validation.pattern' | translate}}
                </mat-label>
            </div>



        </div>



        <mat-form-field appearance="outline">
            <mat-label>
                {{'login.register.password-repeat.label' | translate}}
            </mat-label>
            <input matInput name="passwordRepeat" type="password" formControlName="passwordRepeat"
                placeholder="{{'login.register.password-repeat.placeholder' | translate}}">
            <mat-icon matSuffix>password</mat-icon>
        </mat-form-field>

        <mat-checkbox class="data-protection-regulations-grid" formControlName="dataProtectionRegulations" name="dataProtectionRegulations">
            <a href="https://priceinspect.com/privacy" target="_blank">{{'login.register.gdpr.label' | translate}}</a> {{'login.register.gdpr.label.accept' | translate}}
        </mat-checkbox>
        <!--
                <ion-grid class="data-protection-regulations-grid">
                    <ion-row class="ion-justify-content-center">
                        <ion-col size="auto">
                            <ion-checkbox checked="false" formControlName="dataProtectionRegulations"
                                          name="dataProtectionRegulations"></ion-checkbox>
                        </ion-col>
                        <ion-col>
                            <ion-label>
                                <a href="https://priceinspect.com/privacy" target="_blank">Datenschutzbestimmungen</a> akzeptieren
                            </ion-label>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            -->
        <div class="recaptcha">
            <re-captcha #recaptchaComponent formControlName="recaptcha" (resolved)="captchaResolved($event)"
                siteKey="6Lc5G8IUAAAAAE6VuQu1hu3AjHaUJCpRCxduFLgQ"></re-captcha>
        </div>
        <button mat-button [disabled]="registerForm.invalid" expand="block" type="ngSubmit" class="register-button">
            {{'login.register.register-button.text' | translate}}
        </button>
    </div>
</form>

