import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {PageLayoutService} from "@app/layout/page-layout.service";
import {PageLayout} from "@app/layout/page-layout";

/**
 * Sets the page layout type through a resolver so that before navigating to a component layout is available.
 */
export const setLayout = (inputLayout: PageLayout): ResolveFn<void> => {
  return (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
    inject(PageLayoutService).setLayout(inputLayout)
  }
}
