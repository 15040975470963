import { InjectionToken, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { LegalComponent } from "./pages/legal/legal.component";
import { PrivacyComponent } from "./pages/privacy/privacy.component";
import { PageLayout } from "@app/layout/page-layout";
import { setLayout } from "@app/layout/set-layout.resolver";
import { MobileAppComponent } from "@app/pages/mobile-app/mobile-app.component";
import { ConsentComponent } from './pages/consent/consent.component';
import { CallbackComponent } from './pages/callback/callback.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ErrorComponent } from './pages/error/error.component';
import { IosSafariBrowserNotSupportedComponent } from './pages/ios-safari-browser-not-supported/ios-safari-browser-not-supported.component';
import { EmailLoginComponent } from './pages/email-login/email-login.component';
import { LoginComponent } from './pages/login/login.component';
import { environment } from '@env/environment';
import { Redirect } from './redirect';

const routes: Routes = [
  {
    path: '',
    canActivate: [Redirect],
    component: Redirect,
    data: {
      externalUrl: environment.WEBSITE
    }
  },

  { path: 'login', component: LoginComponent, resolve: { layout: setLayout(PageLayout.HeaderAndFooter) } },
  { path: 'consent', component: ConsentComponent, resolve: { layout: setLayout(PageLayout.HeaderAndFooter) } },
  { path: 'callback/:provider', component: CallbackComponent, resolve: { layout: setLayout(PageLayout.HeaderAndFooter) } },
  { path: 'reset-password', component: PasswordResetComponent, resolve: { layout: setLayout(PageLayout.HeaderAndFooter) } },
  { path: 'register', component: RegisterComponent, resolve: { layout: setLayout(PageLayout.HeaderAndFooter) } },
  { path: 'forgot-password', component: ForgotPasswordComponent, resolve: { layout: setLayout(PageLayout.HeaderAndFooter) } },
  { path: 'error', component: ErrorComponent, resolve: { layout: setLayout(PageLayout.HeaderAndFooter) } },
  {
    path: 'ios-safari-browser-not-supported',
    component: IosSafariBrowserNotSupportedComponent, resolve: { layout: setLayout(PageLayout.HeaderAndFooter) }
  },
  { path: 'email-login', component: EmailLoginComponent, resolve: { layout: setLayout(PageLayout.HeaderAndFooter) } },
  { path: '**', redirectTo: '/' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
