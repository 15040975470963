import {Injectable} from '@angular/core';
import {AngularFireAnalytics} from "@angular/fire/compat/analytics";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private analytics: AngularFireAnalytics) {
  }

  async logEvent(eventName: string, params?: { [key: string]: any; }) {
    if (environment.ANALYTICS_ENABLED && environment.production) {
      await this.analytics.logEvent(eventName, params);
    }
  }

  async enable() {
    if (environment.ANALYTICS_ENABLED && environment.production) {
      await this.analytics.setAnalyticsCollectionEnabled(true);
    }
  }

  async disable() {
    await this.analytics.setAnalyticsCollectionEnabled(false);
  }
}
