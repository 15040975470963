<div class="content-container">
    <h2>{{'login.password-reset.header.title' | translate}}</h2>
    <h3>{{email}}</h3>
</div>

<form *ngIf="!success" [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword(resetPasswordForm)">
    <div class="passwort-reset-container">
        <mat-form-field appearance="outline">
            <mat-label> {{'login.password-reset.password.label' | translate}}</mat-label>
            <input matInput name="password" type="password" formControlName="password"
                placeholder="{{'login.login.email.placeholder' | translate}}">
            <mat-icon matSuffix>password</mat-icon>
        </mat-form-field>


        <div *ngIf="resetPasswordForm.controls['password'].value && resetPasswordForm.controls['password'].errors"
            class="passwort-reset-container">
            <div>
                <mat-icon color="{{resetPasswordForm.controls['password'].errors['pattern'] ? 'danger' : 'success'}}">
                    {{resetPasswordForm.controls['password'].errors['pattern'] ? 'close' : 'checkmark'}}
                </mat-icon>
                <mat-label>
                    {{'login.password-reset.password.validation.pattern' | translate}}
                </mat-label>
            </div>
            <div>
                <mat-icon color="{{resetPasswordForm.controls['password'].errors['minlength'] ? 'danger' : 'success'}}">
                    {{resetPasswordForm.controls['password'].errors['minlength'] ? 'close' : 'checkmark'}}
                </mat-icon>

                <mat-label>
                    {{'login.password-reset.password.validation.minlength' | translate :
                    passwordMinLengthTranslateParam}}
                </mat-label>
            </div>
        </div>


        <mat-form-field appearance="outline">
            <mat-label> {{'login.password-reset.password-repeat.label' | translate}}</mat-label>
            <input matInput name="passwordRepeat" type="password" formControlName="passwordRepeat"
                placeholder="{{'login.password-reset.password-repeat.placeholder' | translate}}">
            <mat-icon matSuffix>password</mat-icon>
        </mat-form-field>

        <button mat-button [disabled]="resetPasswordForm.invalid" class="reset-password-button">
            {{'login.password-reset.reset-password-button.text' |
            translate}}
        </button>
    </div>
</form>
<div *ngIf="success">
    <mat-label>
        <h2>{{'login.password-reset.success.message' | translate}}</h2>
    </mat-label>
    <button mat-button class="option-button" (click)="onToAppClick()">
        {{'login.password-reset.success.to-app-button.text' |
        translate}}
    </button>
</div>