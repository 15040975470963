<div class="header">
  <div class="toolbar" color="accent" [class.mobile]="(sm$ | async)"
       [class.mobile-search-mode]="(sm$ | async) && mobileSearchMode">
    <div class="left">
      <img class="header-logo" src="assets/images/logo-wide_light.png" [routerLink]="'/'"/>
    </div>
    <div class="center">
      <!-- <app-top-search-bar (backClick)="onSearchBackClick()"></app-top-search-bar> -->
    </div>
    <div class="right">
      <!-- 
      <button mat-icon-button (click)="onSearchIconClick()" class="search-icon">
        <mat-icon>search</mat-icon>
      </button>
      <button mat-icon-button routerLink="hot" class="hot-icon" matTooltip="{{'header.buttons.hot' | translate}}" matTooltipClass="toolTip">
        <mat-icon svgIcon="hot-price"></mat-icon>
      </button>
      <button mat-icon-button (click)="onSubscriptionIconClick()" class="subscription-icon" matTooltip="{{'header.buttons.subscriptions' | translate}}" matTooltipClass="toolTip">
        <mat-icon>alarm</mat-icon>
      </button>
      <button mat-icon-button routerLink="app" class="app-icon" matTooltip="{{'header.buttons.app' | translate}}" matTooltipClass="toolTip">
        <mat-icon>install_mobile</mat-icon>
      </button>
      -->
      <button mat-icon-button (click)="onLoginClick()" class="subscription-icon" matTooltip="{{'header.buttons.login' | translate}}" matTooltipClass="toolTip">
        <mat-icon>account_circle</mat-icon>
      </button>
    </div>
  </div>
  <!--<app-category-bar *ngIf="!(sm$ | async)"></app-category-bar> -->
</div>
