import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(private router: Router, private deviceService: DeviceDetectorService) {
  }

  ngOnInit() {
      if (history && history.state && history.state.error) {
          console.error(history.state.error);
      }
  }

  onToLoginClick() {
      this.router.navigateByUrl('login');
  }

  onBackToAppClick() {
      if (this.deviceService.isDesktop()) {
          window.location.href = environment.REDIRECT_URL_Web;
      } else if (this.deviceService.os === 'Android') {
          window.location.href = environment.REDIRECT_URL_Android;
      } else if (this.deviceService.os === 'iOS') {
          window.location.href = environment.REDIRECT_URL_iOS;
      }
  }
}
