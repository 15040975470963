import {ErrorHandler, Injectable, NgZone} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private snackBar: MatSnackBar, private zone: NgZone, private translate: TranslateService) {
  }

  handleError(error: any) {
    this.zone.run(() =>
      this.snackBar.open(this.translate.instant('error.message'), null, {panelClass: 'snackbar-error'})
    );

    console.error('Error from global error handler', error);
  }
}
