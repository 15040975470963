import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { FacebookAuthorizationService } from '@app/services/api/facebook-authorization.service';
import { AppleAuthorizationService } from '@app/services/api/apple-authorization.service';
import { TwitterAuthorizationService } from '@app/services/api/twitter-authorization.service';
import { GoogleAuthorizationService } from '@app/services/api/google-authorization.service';
import { take, tap } from 'rxjs';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginChallenge: string;
  loading: boolean = false;

  constructor(private route: ActivatedRoute,
    private facebookAuthorizationService: FacebookAuthorizationService,
    private appleAuthorizationService: AppleAuthorizationService,
    private twitterAuthorizationService: TwitterAuthorizationService,
    private googleAuthorizationService: GoogleAuthorizationService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private location: Location
   /* private loadingSpinnerService: LoadingSpinnerService*/) {
  }

  ngOnInit() {

    this.route.queryParamMap.subscribe(queryParams => {
      this.loginChallenge = queryParams.get('login_challenge');
      // a login challenge is required
      if (this.loginChallenge == null) {
        this.loginChallenge = window.localStorage.getItem('loginChallenge');
      } else {
        window.localStorage.setItem('loginChallenge', this.loginChallenge);
      }

      
      if (this.deviceService.os === 'iOS') {
        // 'oauth2_authentication_csrf' cookie gets lost on switch from safari web view to safari native browser ->
        // login not possible without this cookie -> not supported by now!
        // to be able to detect this browser switch clear get parameters including login_challenge
        // on switch this.challenge is null/empty then!
        this.location.replaceState('/login', '');
        if (!this.loginChallenge) {
          window.location.href = 'ios-safari-browser-not-supported';
        }
      }
    });
  }


  facebookLogin() {
    this.loading = true;
    this.facebookAuthorizationService.getAuthUrl()
      .pipe(
        take(1))
        //tap(() => this.loading = false))
      .subscribe(authUrl => {
        window.location.href = authUrl;
      },
        (error: HttpErrorResponse) => {
          this.loading = false;

          this.router.navigate(['error'], { state: { error: error.message } });
        });

  }

  twitterLogin() {
    this.loading = true;
    this.twitterAuthorizationService.getAuthUrl()
      .pipe(
        take(1))
       // tap(() => this.loading = false))
      .subscribe(authUrl => {
        window.location.href = authUrl;
      },
        (error: HttpErrorResponse) => {
          this.loading = false;
          this.router.navigate(['error'], { state: { error: error.message } });
        });

  }

  appleLogin() {
    this.loading = true;
    this.appleAuthorizationService.getAuthUrl(this.loginChallenge)
      .pipe(
        take(1))
       // tap(() => this.loading = false))
      .subscribe(authUrl => {
        window.location.href = authUrl;
      },
        (error: HttpErrorResponse) => {
          this.loading = false;
          this.router.navigate(['error'], { state: { error: error.message } });
        });

  }

  googleLogin() {
    this.loading = true;
    this.googleAuthorizationService.getAuthUrl()
      .pipe(
        take(1))
       // tap(() => this.loading = false))
      .subscribe(authUrl => {
        window.location.href = authUrl;
      },
        (error: HttpErrorResponse) => {
          this.loading = false;
          this.router.navigate(['error'], { state: { error: error.message } });
        });

  }


  emailLogin() {
    this.router.navigateByUrl('email-login');
  }
}
