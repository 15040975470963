import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  onFacebookClick() {
    window.open(environment.FACEBOOK_URL);
  }

  onTwitterClick() {
    window.open(environment.TWITTER_URL);
  }

  onContactClick() {
    window.open('mailto:office@priceinspect.com');
  }

  onCookieSettingsClick() {

  }
}
