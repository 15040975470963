<div class="content-container">
  <div class="content">
    <h2>Datenschutzerklärung</h2>

    <h4>Erklärung zur Informationspflicht</h4>
    Der Schutz deiner personenbezogenen Daten ist uns ein besonderes Anliegen. Wir verarbeiten deine Daten daher
    ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO). In diesen Datenschutzinformationen
    informieren wir dich über Aspekte der Datenverarbeitung im Rahmen unserer Website und unserer App. Wird für
    die Erhebung und Verarbeitung deiner Daten deine Einwilligung benötigt, wirst du abseits dieses
    Datenschutzhinweises im Anlassfall darum gebeten.<br/><br/>

    Bei Fragen zu diesem Datenschutzhinweis kannst du dich per E-Mail <a href="mailto:gdpr@priceinspect.com">(gdpr (at)priceinspect.com)</a>
    an uns wenden. <br/><br/>Verantwortlicher im Sinne
    des Art 4 Nr. 7 DSGVO ist die Anthrazit Consulting GmbH, Kirchengasse 5, A-4623 Gunskirchen.

    <h4>Kontakt mit uns</h4>
    Wenn du per Formular auf der Website, über die Feedback-Funktion in der App oder per E-Mail Kontakt mit uns
    aufnehmen willst, werden deine angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von
    Anschlussfragen sechs Monate bei uns gespeichert. Diese Daten geben wir nicht ohne deine Einwilligung weiter.

    <h4>Cookies</h4>
    Unsere Website und App verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit
    Hilfe des Browsers auf deinem Endgerät abgelegt werden. Sie richten keinen Schaden an.<br/>

    Wir nutzen Cookies dazu unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf deinem
    Endgerät gespeichert bis du diese löschst. Cookies ermöglichen es uns, deinen Browser beim nächsten Besuch
    wiederzuerkennen. <br/>

    Wenn du dies nicht wünscht, kannst du deinen Browser so konfigurieren, dass dieser dich über das Setzen von
    Cookies informiert und dies nur im Einzelfall erlaubt. Bei der Deaktivierung von Cookies kann die
    Funktionalität unserer Website und App eingeschränkt sein.<br/><br/>

    In den <span class="cookie-settings" (click)="onCookieSettingsClick()">Cookie-Einstellungen</span> kannst du die
    Verwendung von Cookies jederzeit anpassen.

    <h4>Web- und App-Analyse</h4>
    Unsere Website und App verwenden Funktionen des Webanalysedienstes Google Analytics for Firebase der Google
    Ireland Limited ("Google"), Gordon House, Barrow Street, Dublin 4, Ireland. <br/>

    Google Analytics for Firebase verwendet Cookies, welche auf deinem Computer gespeichert werden und eine
    Analyse der Website- und App-Nutzung ermöglichen. Die durch Cookies ermittelten Informationen über die
    Nutzung der Website oder App werden in der Regel an einen Server von Google in Irland, Frankfurt oder in
    Ausnahmefällen in den USA übertragen und dort gespeichert. <br/>

    Nähere Informationen zu Nutzungsbedingungen und Datenschutz findest du unter
    <a href="https://firebase.google.com/terms/analytics">https://firebase.google.com/terms/analytics</a> bzw.
    unter <a href="https://firebase.google.com/policies/analytics">https://firebase.google.com/policies/analytics</a>.

    Der Zweck der Nutzung von Google Analytics for Firebase liegt in der Analyse des anonymisierten
    Nutzerverhaltens und der Verbesserung der User-Experience.

    <h4>Newsletter</h4>
    Du hast die Möglichkeit unseren Newsletter über unsere Website oder App zu abonnieren. Hierfür benötigen wir
    deine E-Mail-Adresse und deine Erklärung, dass du mit dem Bezug des Newsletters einverstanden bist. <br/>
    Sobald du dich für den Newsletter angemeldet hast, senden wir dir ein Bestätigungs-E-Mail.
    Das Abo des Newsletters kannst du jederzeit widerrufen. Dies erfolgt durch Klicken auf den Abmelde-Link in
    der E-Mail. Wir löschen anschließend umgehend deine Daten im Zusammenhang mit dem Newsletter-Versand. Durch
    diesen Widerruf wird die Rechtmäßigkeit der aufgrund der Zustimmung bis zum Widerruf erfolgten Verarbeitung
    nicht berührt.


    <h4>Wann und warum werden Daten erhoben?</h4>

    Von Beginn an ist uns der Schutz deiner personenbezogenen Daten wichtig. Wir haben nicht vor personenbezogene
    Auswertungen durchzuführen oder diese Dritten zur Verfügung zu stellen. Aus diesem Grund anonymisieren wir
    deine Daten. Nachfolgend führen wir an, wann wir welche Daten von dir speichern und warum:
    <br/><br/>
    <ul>
      <li>Registrierung
        <ul>Wenn du dich bei uns registrierst speichern wir deine E-Mail-Adresse. Nutzt du Facebook oder Twitter
          zur Anmeldung bei uns, weisen wir dich direkt zum Zeitpunkt des ersten Logins darauf hin, welche Daten
          von diesen Plattformen priceinspect.com zur Verfügung gestellt werden. Die Registrierung ist erforderlich,
          um eine Einkaufsliste erstellen zu können.
        </ul>
      </li>
      <br/>
      <li>Einkaufsliste
        <ul>Einzelne Produkte oder Suchbegriffe werden auf deiner Einkaufsliste gespeichert. Die Einkaufsliste ist
          mit deinem Benutzerkonto verknüpft.
        </ul>
      </li>
      <br/>
      <li>Bildsuche
        <ul>Bei der Suche von Produkten auf Basis eines Fotos wird das Foto zu den Servern von priceinspect.com
          übermittelt, um passende Produktergebnisse anzeigen zu können.
        </ul>
      </li>
      <br/>
      <li>Google Admob
        <ul>Wir verwenden Google Admob um innerhalb der App Werbung zu platzieren. Dazu wird die Werbe-ID deines
          Geräts
          ausgelesen und an Google übermittelt.
        </ul>
      </li>
      <br/>
      <li>Google Analytics
        <ul>Wir verwenden Google Analytics, um die Website bzw. App-Nutzung zu analysieren. Die daraus gewonnenen
          Daten
          werden genutzt, um unsere App zu optimieren.
          Zu diesen Daten gehören unter anderem App-Start, Klicks und Scrollen. All diese Daten können nicht auf
          Einzelpersonen zurückgeführt werden.
        </ul>
      </li>
      <br/>
      <li>Absturzdaten
        <ul>Kommt es bei der Verwendung der App zu einem Absturz, werden Fehlerprotokolle zu den Servern von
          priceinspect.com übermittelt.
        </ul>
      </li>
    </ul>

    <h4>Datenlöschung und Speicherdauer</h4>
    Die Speicherung deiner personenbezogenen Daten erfolgt solange, wie es für den Zweck, für welchen diese Daten
    erhoben wurden, erforderlich ist. In der App besteht die Möglichkeit das Benutzerkonto und alle damit
    verbundenen personenbezogenen Daten zu löschen (Einstellungen > Benutzerkonto löschen). Die gesetzlichen
    Aufbewahrungsfristen bleiben davon unberührt.

    <h4>Deine Rechte</h4>
    Dir stehen bezüglich deiner bei uns gespeicherten Daten grundsätzlich die Rechte auf Auskunft, Berichtigung,
    Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn du glaubst, dass die
    Verarbeitung deiner Daten gegen das Datenschutzrecht verstößt oder deine datenschutzrechtlichen Ansprüche
    sonst in einer Weise verletzt worden sind, kannst du dich bei uns via E-Mail
    <a href="mailto:gdpr@priceinspect.com">(gdpr(at)priceinspect.com)</a> oder der Datenschutzbehörde beschweren.

    Hier findest du unsere <a href="/legal">Kontaktdaten</a>.
  </div>
</div>
