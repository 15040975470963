<div class="footer">
  <ul class="social-icons">
    <li>
      <button mat-icon-button class="social-icon" (click)="onFacebookClick()">
        <mat-icon svgIcon="facebook-logo"></mat-icon>
      </button>
    </li>
    <li>
      <button mat-icon-button class="social-icon" (click)="onTwitterClick()">
        <mat-icon svgIcon="twitter-logo"></mat-icon>
      </button>
    </li>
  </ul>
  <ul class="footer-menu">
    <li class="menu-item"><a routerLink="/">{{'footer.home' | translate}}</a></li>
    <li class="menu-item"><a href="https://download.priceinspect.com" target="_blank">{{'footer.app-download' | translate}}</a></li>
    <li class="menu-item"><a routerLink="/privacy">{{'footer.privacy' | translate}}</a></li>
    <li class="menu-item"><a routerLink="/legal">{{'footer.legal' | translate}}</a></li>
    <li class="menu-item"><a (click)="onContactClick()">{{'footer.contact' | translate}}</a></li>
  </ul>
  <p>{{'footer.copyright' | translate}}</p>
</div>
