<div class="content-container">
  <div class="content">
    <h2>Impressum</h2>

    <h4>Informationen und Offenlegung gemäß § 5 (1) ECG, § 25 MedienG, § 63 GewO und
      § 14 UGB</h4>
    <b>Anthrazit Consulting GmbH</b> <br/>
    Kirchengasse 5<br/>
    4623 Gunskirchen
    <p>
      <b>Firmenbuchnummer</b><br/>523087x<br/><br/>
      <b>Firmenbuchgericht</b><br/>Landesgericht Wels
    </p>
    <h4>Online Streitbeilegung</h4>
    Verbraucher, welche in Österreich oder in einem sonstigen Vertragsstaat der ODR-VO
    niedergelassen sind, haben die Möglichkeit Probleme bezüglich dem entgeltlichen Kauf von Waren oder
    Dienstleistungen
    im Rahmen einer Online-Streitbeilegung (nach OS, AStG) zu lösen. Die Europäische Kommission stellt eine Plattform
    hierfür bereit: https://ec.europa.eu/consumers/odr

    <h4>Urheberrecht</h4>
    Die Inhalte dieser Webseite unterliegen, soweit dies rechtlich möglich ist, diversen
    Schutzrechten (z.B dem Urheberrecht). Jegliche Verwendung/Verbreitung von bereitgestelltem Material, welche
    urheberrechtlich untersagt ist, bedarf schriftlicher Zustimmung des Webseitenbetreibers.

    <h4>Haftungsausschluss</h4> Trotz sorgfältiger inhaltlicher Kontrolle übernimmt der Webseitenbetreiber dieser
    Webseite
    keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren
    Betreiber
    verantwortlich. Sollten Sie dennoch auf ausgehende Links aufmerksam werden, welche auf eine Webseite mit
    rechtswidriger Tätigkeit/Information verweisen, ersuchen wir um dementsprechenden Hinweis, um diese nach § 17 Abs.
    2
    ECG umgehend zu entfernen.<br>
    Die Urheberrechte Dritter werden vom Betreiber dieser Webseite mit größter Sorgfalt
    beachtet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
    entsprechenden
    Hinweis. Bei Bekanntwerden derartiger Rechtsverletzungen werden wir den betroffenen Inhalt umgehend entfernen.
  </div>
</div>
