import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-ios-safari-browser-not-supported',
  templateUrl: './ios-safari-browser-not-supported.component.html',
  styleUrls: ['./ios-safari-browser-not-supported.component.scss']
})
export class IosSafariBrowserNotSupportedComponent implements OnInit {

    constructor(private deviceService: DeviceDetectorService) {
    }

    ngOnInit() : void {
        console.log('i got it!!!');
    }

    onBackToAppClick() {
        if (this.deviceService.isDesktop()) {
            window.location.href = environment.REDIRECT_URL_Web;
        } else if (this.deviceService.os === 'Android') {
            window.location.href = environment.REDIRECT_URL_Android;
        } else if (this.deviceService.os === 'iOS') {
            window.location.href = environment.REDIRECT_URL_iOS;
        }
    }

}
