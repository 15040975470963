import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '@env/environment';
import { PasswordResetVerificationResult } from '@app/model/model';


@Injectable({
    providedIn: 'root'
})
export class UserManagementService {

    constructor(public http: HttpClient) {
    }

    public verifyLogin(email: string, password: string): Observable<string> {
        return this.http.post(environment.API_URL + '/usermanagement/verify-login', {email, password}, {responseType: 'text'});
    }

    public register(email: string, password: string, recaptchaChallenge: string): Observable<string> {
        return this.http.post(environment.API_URL + '/usermanagement/register', {
            email,
            password,
            recaptchaChallenge
        }, {responseType: 'text'});
    }

    public sendPasswordResetMail(email: string, recaptchaChallenge: string): Observable<any> {
        return this.http.post(environment.API_URL + '/usermanagement/reset-password/send-mail', {email, recaptchaChallenge});
    }

    public verifyPasswordReset(resetToken: string): Observable<PasswordResetVerificationResult> {
        return this.http.post<PasswordResetVerificationResult>(environment.API_URL + '/usermanagement/reset-password/verify', {resetToken});
    }

    public resetPassword(resetToken: string, userId: string, password: string): Observable<any> {
        return this.http.post(environment.API_URL + '/usermanagement/reset-password', {resetToken, userId, password});
    }

}
