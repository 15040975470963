import { Component, OnInit } from '@angular/core';
import {environment} from "@env/environment";

@Component({
  selector: 'app-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.scss']
})
export class MobileAppComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  onIOSStoreBadgeClick() {
    window.open(environment.IOS_STORE_URL);
  }

  onAndroidStoreBadgeClick() {
    window.open(environment.ANDROID_STORE_URL);
  }

}
