import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserManagementService } from '@app/services/api/user-management.service';
import { MustMatch } from '@app/validators/must-match.validator';
import { environment } from '@env/environment';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {


  email: string;
  userId: string;
  resetToken: string;
  enabled = false;
  success = false;
  resetPasswordForm: FormGroup;
  passwordMinLength = 8;
  passwordMinLengthTranslateParam = {value: this.passwordMinLength};

  constructor(private route: ActivatedRoute,
              private router: Router,
              private formBuilder: FormBuilder,
              private userManagementService: UserManagementService,
              private deviceService: DeviceDetectorService) {
  }

  ngOnInit() {
      this.resetPasswordForm = this.formBuilder.group({
          password: new FormControl('', Validators.compose([
              Validators.required,
              Validators.minLength(this.passwordMinLength),
              Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).+$')
          ])),
          passwordRepeat: new FormControl('', Validators.required)
      }, {
          validator: MustMatch('password', 'passwordRepeat')
      });

      this.route.queryParamMap.subscribe(queryParams => {
          this.resetToken = queryParams.get('resetToken');
          if (this.resetToken == null) {
              // challenge can be null on page refresh
              this.resetToken = window.localStorage.getItem('resetToken');
          } else {
              window.localStorage.setItem('resetToken', this.resetToken);
          }
          if(this.resetToken == null) {
            this.router.navigate(['error'], {state: {error: null}});
          }
          else {
            this.verifyPasswortReset(this.resetToken);
          }
      });
  }

  verifyPasswortReset(resetToken: string) {
      this.userManagementService.verifyPasswordReset(resetToken).toPromise().then(response => {
          this.email = response.email;
          this.userId = response.userId;
          this.enabled = true;
      });
  }

  resetPassword(resetPasswordForm: FormGroup) {
      this.userManagementService.resetPassword(this.resetToken, this.userId, resetPasswordForm.value.password).toPromise().then(() => {
          this.success = true;
      }, (error: HttpErrorResponse) => {
          this.router.navigate(['error'], {state: {error: error.error}});
      });
  }

  onToAppClick() {
      if (this.deviceService.isDesktop()) {
          window.location.href = environment.REDIRECT_URL_Web;
      } else if (this.deviceService.os === 'Android') {
          window.location.href = environment.REDIRECT_URL_Android;
      } else if (this.deviceService.os === 'iOS') {
          window.location.href = environment.REDIRECT_URL_iOS;
      }
  }
}
