<div class="content-container">
    <h2>{{'login.login.header.title' | translate}}</h2>
    <h3>{{'login.login.header.subtitle' | translate}}</h3>
</div>

<div *ngIf="!loading" class="content-container">
    <button mat-icon-button (click)="emailLogin()" class="email-login-button" style="text-transform: none;">
        {{'login.login.email-login-button.text' | translate}}
    </button>

    <button mat-icon-button (click)="appleLogin()" class="apple-login-button">
        <img class="apple-logo" src="assets/images/Apple_logo_white.svg" slot="start">
        {{'login.login.apple-login-button.text' | translate}}
    </button>

    <button mat-icon-button (click)="facebookLogin()" class="facebook-login-button" style="text-transform: none;">
        <img class="facebook-logo" src="assets/images/f_logo_RGB-White_58.png" slot="start">
        {{'login.login.facebook-login-button.text' | translate}}
    </button>

    <button mat-icon-button (click)="twitterLogin()" class="twitter-login-button" style="text-transform: none;">
        <img class="twitter-logo" src="assets/images/Twitter_Logo_Blue.svg" slot="start">
        {{'login.login.twitter-login-button.text' | translate}}
    </button>

    <button mat-icon-button (click)="googleLogin()" class="google-login-button" style="text-transform: none;">
        <img class="google-logo" src="assets/images/google-icon.svg" slot="start">
        {{'login.login.google-login-button.text' | translate}}
    </button>
    <p></p>
</div>
<div class="loading-spinner-box" *ngIf="loading">
    <mat-spinner class="loading-spinner"></mat-spinner>
</div>