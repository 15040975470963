import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginAccepted } from '@app/model/model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  constructor(private http: HttpClient) {
  }


  public consent(challenge: string): Observable<LoginAccepted> {
    return this.http.post<LoginAccepted>(environment.API_URL + '/authorization/consent', { challenge });
  }


  public acceptLoginRequest(loginChallenge: string, userId: string): Observable<LoginAccepted> {
    return this.http.post<LoginAccepted>(environment.API_URL + '/authorization/accept-login', { loginChallenge, userId });
  }
}
