<div class="content-container">
    <button mat-icon-button (click)="onBackClick()" class="back-button" style="text-transform: none;" color="secondary">
        {{'login.login.back.label' | translate}}
    </button>
</div>

<form [formGroup]="loginForm" (ngSubmit)="login(loginForm)">
    <div class="login-container">
        <mat-form-field appearance="outline">
            <mat-label>{{'login.login.email.label' | translate}}</mat-label>
            <input matInput formControlName="email" name="email" type="email"
                placeholder="{{'login.login.email.placeholder' | translate}}">
            <mat-icon matSuffix>account_circle</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label> {{'login.login.password.label' | translate}}</mat-label>
            <input matInput formControlName="password" name="password" type="password"
                (keypress)="onPasswordKeypress($event)"
                placeholder="{{'login.login.password.placeholder' | translate}}">
            <mat-icon matSuffix>password</mat-icon>
        </mat-form-field>

        <button mat-button class="login-button" [disabled]="loginForm.invalid" type="ngSubmit">
            {{'login.login.login-button.text' | translate}}
        </button>

        <div *ngIf="loginFailure" class="login-error">
            <mat-error>{{'login.login.error.login-failed' | translate}} </mat-error>
        </div>
      
        <button mat-button class="option-button" routerLink="/forgot-password">
            {{'login.login.forgot-password-button.text' | translate}}
        </button>
        <button mat-button class="option-button" routerLink="/register">
            {{'login.login.register-button.text' | translate}}
        </button>
    </div>
</form>
