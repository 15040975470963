// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'aktionsguru-260418',
    appId: '1:703986300620:web:dfd84f0bd90c53a7fcf469',
    databaseURL: 'https://aktionsguru-260418.firebaseio.com',
    storageBucket: 'aktionsguru-260418.appspot.com',
    apiKey: 'AIzaSyBbu0Nl0aZfzI4UwFJ3_OGpM7N4R1duSx8',
    authDomain: 'aktionsguru-260418.firebaseapp.com',
    messagingSenderId: '703986300620',
    measurementId: 'G-ER923G9FP0'
  },
  production: false,
  DEFAULT_LANG: 'de',
  ANALYTICS_ENABLED: false,
  FACEBOOK_URL: "https://www.facebook.com/priceinspect",
  TWITTER_URL: "https://twitter.com/PriceInspect",
  ANDROID_STORE_URL: "https://play.google.com/store/apps/details?id=com.aktions.guru",
  IOS_STORE_URL: "https://apps.apple.com/at/app/aktions-guru/id1492349489",
  BASE_URL: 'https://api.keepfile.at/',
  API_URL: 'https://api.keepfile.at/v1',
  CDN_URL: 'https://api.keepfile.at/cdn',


  ISSUER: 'https://auth.keepfile.at/',
  REDIRECT_URL_iOS: 'aktionsguru://priceinspect.com/oauth/callback',
  REDIRECT_URL_Android: 'aktionsguru://priceinspect.com/oauth/callback',
  REDIRECT_URL_Web: 'http://localhost:4200/callback',
  WEBSITE: "https://priceinspect.com"
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
