import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppleAuthorizationService } from '@app/services/api/apple-authorization.service';
import { FacebookAuthorizationService } from '@app/services/api/facebook-authorization.service';
import { GoogleAuthorizationService } from '@app/services/api/google-authorization.service';
import { TwitterAuthorizationService } from '@app/services/api/twitter-authorization.service';
import { UserManagementService } from '@app/services/api/user-management.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {


  constructor(private route: ActivatedRoute,
    private userManagementService: UserManagementService,
    private facebookAuthorizationService: FacebookAuthorizationService,
    private appleAuthorizationService: AppleAuthorizationService,
    private twitterAuthorizationService: TwitterAuthorizationService,
    private googleAuthorizationService: GoogleAuthorizationService,
    private router: Router) {
  }


  ngOnInit() {
    const provider = this.route.snapshot.paramMap.get('provider');
    const loginChallenge = window.localStorage.getItem('loginChallenge');

    this.route.queryParamMap.subscribe(queryParams => {
      if (provider.toLowerCase() === 'twitter') {
        const oauthToken = queryParams.get('oauth_token');
        const oauthVerifier = queryParams.get('oauth_verifier');

        if (oauthToken && oauthVerifier) {
          this.twitterLogin(oauthToken, oauthVerifier, loginChallenge);
        } else {
          console.error('twitter authorization failed');
          this.router.navigate(['login'], { queryParams: { login_challenge: loginChallenge } });
        }
      } else if (provider.toLowerCase() === 'facebook') {
        const errorReason = queryParams.get('error_reason');
        const errorDescription = queryParams.get('error_description');
        const error = queryParams.get('error');
        const code = queryParams.get('code');
        if (code) {
          this.facebookLogin(code, loginChallenge);
        } else {
          console.error('facebook authorization failed');
          console.error('error_reason', errorReason);
          console.error('error_description', errorDescription);
          console.error('error', error);
          this.router.navigate(['login'], { queryParams: { login_challenge: loginChallenge } });
        }
      } else if (provider.toLowerCase() === 'google') {
        const code = queryParams.get('code');
        if (code) {
          this.googleLogin(code, loginChallenge);
        } else {
          console.error('queryParams', queryParams);
          this.router.navigate(['login'], { queryParams: { login_challenge: loginChallenge } });
        }
      }
    });
  }

  facebookLogin(verificationCode: string, loginChallenge: string) {
    this.facebookAuthorizationService.login(verificationCode, loginChallenge).pipe(
      take(1)
    ).subscribe(loginAccepted => {
      window.location.href = loginAccepted.redirectUrl;
    }, (error: HttpErrorResponse) => {
      this.router.navigate(['error'], { state: { error: error.message } });
    });
  }

  private twitterLogin(oauthToken: string, oauthVerifier: string, loginChallenge: string) {
    this.twitterAuthorizationService.login(oauthToken, oauthVerifier, loginChallenge).pipe(
      take(1)
    ).subscribe(loginAccepted => {
      window.location.href = loginAccepted.redirectUrl;
    }, (error: HttpErrorResponse) => {
      this.router.navigate(['error'], { state: { error: error.message } });
    });
  }

  private googleLogin(authCode: string, loginChallenge: string) {
    this.googleAuthorizationService.login(authCode, loginChallenge).pipe(
      take(1)
    ).subscribe(loginAccepted => {
      window.location.href = loginAccepted.redirectUrl;
    }, (error: HttpErrorResponse) => {
      this.router.navigate(['error'], { state: { error: error.message } });
    });
  }
}
