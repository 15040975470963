import {Injectable} from '@angular/core';
import * as CookieConsent from "vanilla-cookieconsent";
import {TranslateService} from "@ngx-translate/core";
import {AnalyticsService} from "./analytics.service";
import {last} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CookieConsentService {

  constructor(private translateService: TranslateService, private analyticsService: AnalyticsService) {

  }

  async init() {
    await CookieConsent.setLanguage(this.translateService.currentLang);

    this.translateService.onLangChange.subscribe(async (langChangeEvent) => {
      await CookieConsent.setLanguage(langChangeEvent.lang);
    });

    const currentCookie = CookieConsent.getCookie();
    if (currentCookie && currentCookie.lastConsentTimestamp) {
      // according to GDPR cookie consent must be reset every year
      const lastConsentDate = new Date(currentCookie.lastConsentTimestamp);
      lastConsentDate.setFullYear(lastConsentDate.getFullYear()+1);
      if (lastConsentDate < new Date()) {
        CookieConsent.reset(true);
      }
    }

    await CookieConsent.run({

      onFirstConsent: (param) => this.onFirstConsent(param),

      onConsent: (param) => this.onConsent(param),

      onChange: (param) => {
        this.onChange(param);
      },

      disablePageInteraction: true,
      autoClearCookies: true,
      hideFromBots: true,
      revision: 1,

      guiOptions: {
        consentModal: {
          layout: 'box inline',
          position: 'middle center',
          equalWeightButtons: true,
          flipButtons: false
        },
        preferencesModal: {
          layout: 'box',
          position: 'right',
          equalWeightButtons: true,
          flipButtons: false
        }
      },

      categories: {
        necessary: {
          enabled: true,  // this category is enabled by default
          readOnly: true  // this category cannot be disabled
        },
        analytics: {
          enabled: false,
          readOnly: false,

          // Delete specific cookies when the user opts-out of this category
          autoClear: {
            cookies: [
              {
                name: /^_ga/,   // regex: match all cookies starting with '_ga'
              },
              {
                name: '_gid',   // string: exact cookie name
              }
            ]
          }
        }
      },

      language: {
        default: 'de',

        translations: {
          de: {
            consentModal: {
              title: 'Cookie Einstellungen',
              description: 'Wenn du Cookies akzeptierst hilfst du uns dabei das Nutzerverhalten besser zu verstehen und unsere Webseite dementsprechend zu optimieren.',
              acceptAllBtn: 'Alle akzeptieren',
              acceptNecessaryBtn: 'Alle ablehnen',
              showPreferencesBtn: 'Individuell einstellen'
            },
            preferencesModal: {
              title: 'Cookie Einstellungen',
              acceptAllBtn: 'Alle akzeptieren',
              acceptNecessaryBtn: 'Alle ablehnen',
              savePreferencesBtn: 'Aktuelle Auswahl bestätigen',
              closeIconLabel: 'Schließen',
              sections: [
                {
                  title: 'Warum Cookies?',
                  description: 'Cookies sind kleine Dateien in deinem Browser mit technischen Informationen.<br/>Wenn du Cookies akzeptierst hilfst du uns dabei das Nutzerverhalten besser zu verstehen und unsere Webseite dementsprechend zu optimieren.<br/>Alle Informationen sind anonymisiert und können nicht verwendet werden um dich zu identifizieren.'
                },
                {
                  title: 'System',
                  description: 'Diese Cookies sind essentiell für die Webseite selbst und können nicht deaktiviert werden.',

                  //this field will generate a toggle linked to the 'necessary' category
                  linkedCategory: 'necessary'
                },
                {
                  title: 'Analyse und Performance',
                  description: 'Diese Cookies sammeln Informationen über deine Nutzung der Webseite. All diese Informationen sind anonymisiert und können nicht verwendet werden um dich zu identifizieren.',
                  linkedCategory: 'analytics'
                },
                {
                  title: 'Weitere Informationen',
                  description: 'Mehr Informationen findest du in unserer <a href="/privacy" target="_blank">Datenschutzerklärung</a>.'
                }
              ]
            }
          }
        }
      }
    });
  }

  private onFirstConsent(param: { cookie: CookieConsent.CookieValue }) {
    if (param.cookie.categories.includes('analytics')) {
      this.analyticsService.logEvent('analytics.firstConsent.accepted');
    } else {
      this.analyticsService.logEvent('analytics.firstConsent.rejected');
    }
  }

  private onConsent(param: { cookie: CookieConsent.CookieValue }) {
    if (param.cookie.categories.includes('analytics')) {
      this.enableGoogleAnalytics();
    } else {
      this.disableGoogleAnalytics();
    }
  }

  private onChange(param: { cookie: CookieConsent.CookieValue; changedCategories: string[]; changedServices: { [p: string]: string[] } }) {
    if (param.changedCategories.includes('analytics')) {
      if (param.cookie.categories.includes('analytics')) {
        this.analyticsService.logEvent('analytics.change.accepted');
        this.enableGoogleAnalytics();
      } else {
        this.analyticsService.logEvent('analytics.change.rejected');
        this.disableGoogleAnalytics();
      }
    }
  }

  openCookieSettingsDialog() {
    CookieConsent.showPreferences();
  }

  private enableGoogleAnalytics() {
    this.analyticsService.enable();
  }

  private disableGoogleAnalytics() {
    this.analyticsService.disable();
  }
}
