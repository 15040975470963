<!--<div *ngIf="appState.loading" class="fixed z-50 h-full w-full">-->
<!--  <div class="app-loading-container mx-auto">-->
<!--    <svg class="spinner" viewBox="25 25 50 50">-->
<!--      <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />-->
<!--    </svg>-->
<!--  </div>-->
<!--</div>-->


<ng-container [ngSwitch]="pageLayoutService.layout$ | async">
  <app-layout-header-footer *ngSwitchCase="PageLayout.HeaderAndFooter">
    <router-outlet></router-outlet>
  </app-layout-header-footer>
  <app-layout-header *ngSwitchCase="PageLayout.Header">
    <router-outlet></router-outlet>
  </app-layout-header>
  <router-outlet *ngSwitchCase="PageLayout.Blank"></router-outlet>
</ng-container>
