<div class="content-container">
    <h2>{{'login.forgot-password.header.title' | translate}}</h2>
    <h3>{{'login.forgot-password.header.subtitle' | translate}}</h3>
</div>
<form [formGroup]="forgotPasswordForm" (ngSubmit)="sendPasswordResetMail(forgotPasswordForm)">
    <div class="forgot-password-container">
        <mat-form-field appearance="outline">
            <mat-label position="stacked">
                {{'login.forgot-password.email.label' | translate}}
            </mat-label>
            <input matInput formControlName="email" name="email" type="email"
                placeholder="{{'login.forgot-password.email.placeholder' | translate}}">
            <mat-icon matSuffix>email</mat-icon>
        </mat-form-field>
        <div class="recaptcha">
            <re-captcha #recaptchaComponent formControlName="recaptcha" (resolved)="captchaResolved($event)"
                siteKey="6Lc5G8IUAAAAAE6VuQu1hu3AjHaUJCpRCxduFLgQ"></re-captcha>
        </div>
        <button mat-button [disabled]="forgotPasswordForm.invalid" class="send-mail-button" expand="block"
            type="ngSubmit">
            {{'login.forgot-password.send-mail-button.text' | translate}}
        </button>


        <div *ngIf="success" class="reset-password-success">
            <mat-label>{{'login.forgot-password.mail-sent' | translate}}</mat-label>
        </div>

    </div>
</form>