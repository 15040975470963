import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiError } from '@app/model/error/apiError';
import { ErrorCodes } from '@app/model/error/errorCodes';
import { AuthorizationService } from '@app/services/api/authorization.service';
import { UserManagementService } from '@app/services/api/user-management.service';
import { mergeMap, take, tap } from 'rxjs';

@Component({
  selector: 'app-email-login',
  templateUrl: './email-login.component.html',
  styleUrls: ['./email-login.component.scss']
})
export class EmailLoginComponent implements OnInit {

  loginChallenge: string;
  loginFailure = false;
  loginForm: FormGroup;
  loading: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private userManagementService: UserManagementService,
    private authorizationService: AuthorizationService,
    private router: Router) {
    this.loginChallenge = window.localStorage.getItem('loginChallenge');
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.email
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required
      ]))
    });
  }

  login(form: FormGroup) {
    this.loginFailure = false;

    this.userManagementService.verifyLogin(form.value.email, form.value.password).pipe(
      take(1),
      mergeMap(userId => this.authorizationService.acceptLoginRequest(this.loginChallenge, userId)),
      tap(() => this.loading = false)
    ).subscribe(loginResult => {
      window.location.href = loginResult.redirectUrl;
    }, (error: HttpErrorResponse) => {
      this.loading = false;
      if (error.error) {
        const apiError: ApiError = JSON.parse(error.error);
        if (apiError.errorCode === ErrorCodes.NOT_AUTHORIZED) {
          this.loginFailure = true;
          return;
        }
      }
      this.router.navigate(['error'], { state: { error: error.message } });
    });

  }

  onPasswordKeypress(event: KeyboardEvent) {
    // prevent form submit on "enter"-press
    if (event.key === 'Enter') {
      event.preventDefault();
      // TODO: keyboard.hide() does not take affect...
      if (this.loginForm.valid) {
        this.login(this.loginForm);
      }
    }
  }

  onBackClick() {
    this.router.navigateByUrl('login');
  }

}

