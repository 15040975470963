import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationService } from '@app/services/api/authorization.service';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {

  constructor(private route: ActivatedRoute, public authorizationService: AuthorizationService, private router: Router) {
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe(queryParams => {
      const consentChallenge = queryParams.get('consent_challenge');

      this.authorizationService.consent(consentChallenge).toPromise().then(result => {
        console.log('consent.clear');
        window.localStorage.clear();
        window.location.href = result.redirectUrl;
      },
        (error: HttpErrorResponse) => {
          this.router.navigate(['error'], { state: { error: error.message } });
        });

    });
  }

}
