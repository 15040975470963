import {Component, OnDestroy, OnInit} from '@angular/core';
import {filter, mergeMap, Observable, Subscription} from 'rxjs';
import {CookieConsentService} from './services/cookie-consent.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {PageLayout} from './layout/page-layout';
import {PageLayoutService} from '@app/layout/page-layout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  layout$ = this.getLayoutType$()
  readonly AppLayoutType = PageLayout

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              public pageLayoutService: PageLayoutService,
              private cookieConsentService: CookieConsentService) {

  }

  ngOnInit(): void {
    this.cookieConsentService.init();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private getLayoutType$(): Observable<PageLayout> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild
        }
        return route
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
      map(({layout}) => layout)
    );
  }

  protected readonly PageLayout = PageLayout;
}
