import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Locale} from "./locale";

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(public http: HttpClient) {
  }

  public loadTranslations(lang): Observable<any> {
    return this.http.get('/assets/i18n/' + lang + '.json');
  }

  public getAvailableLanguages(): Observable<Locale[]> {
    return of([Locale.GERMAN]);
  }

}
