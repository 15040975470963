export class Locale {
  public static GERMAN: Locale = new Locale('Deutsch', 'de');
  displayLanguage: string;
  tag: string;

  constructor(displayLanguage: string, tag: string) {
    this.displayLanguage = displayLanguage;
    this.tag = tag;
  }
}
