import {Component, Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router} from '@angular/router';

/*
@Injectable({
    providedIn: 'root'
})
*/

@Component({
    selector: 'app-privacy',
    template: '',
  })
export class Redirect implements CanActivate {

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {

      window.location.href = route.data['externalUrl'];
      return true;

  }
}
