import {APP_INITIALIZER, ErrorHandler, InjectionToken, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {FooterComponent} from './components/footer/footer.component';
import {HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslationService} from './services/i18n/translation.service';
import {TranslationsLoader} from './services/i18n/translations-loader';
import {LegalComponent} from './pages/legal/legal.component';
import {PrivacyComponent} from './pages/privacy/privacy.component';
import {MatCardModule} from '@angular/material/card';
import {environment} from '@env/environment';
import {ScreenTrackingService} from '@angular/fire/analytics';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAnalyticsModule} from '@angular/fire/compat/analytics';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {VirtualScrollerModule} from '@daalex90/ngx-virtual-scroller';
import {InitializationService} from './services/initialization.service';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {GlobalErrorHandler} from './global-error-handler';
import {SharedModule} from './components/shared/shared.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {MatExpansionModule} from '@angular/material/expansion';
import {LayoutHeaderComponent} from './layout/layout-header/layout-header.component';
import {LayoutHeaderFooterComponent} from '@app/layout/layout-header-footer/layout-header-footer.component';
import {HeaderComponent} from './components/header/header.component';
import {ObserversModule} from '@angular/cdk/observers';
import {MatCheckboxModule} from '@angular/material/checkbox';

import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {MatSelectModule} from '@angular/material/select';
import {MatChipsModule} from '@angular/material/chips';
import {NgOptimizedImage, registerLocaleData} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MobileAppComponent} from '@app/pages/mobile-app/mobile-app.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';



import localeDe from '@angular/common/locales/de';
import { CallbackComponent } from './pages/callback/callback.component';
import { ConsentComponent } from './pages/consent/consent.component';
import { EmailLoginComponent } from './pages/email-login/email-login.component';
import { ErrorComponent } from './pages/error/error.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { IosSafariBrowserNotSupportedComponent } from './pages/ios-safari-browser-not-supported/ios-safari-browser-not-supported.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { RegisterComponent } from './pages/register/register.component';
import { Redirect } from './redirect';
import { LoginComponent } from './pages/login/login.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

registerLocaleData(localeDe);


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    LegalComponent,
    PrivacyComponent,
    LayoutHeaderFooterComponent,
    LayoutHeaderComponent,
    HeaderComponent,
    MobileAppComponent,
    LoginComponent,
    CallbackComponent,
    ConsentComponent,
    EmailLoginComponent,
    ErrorComponent,
    ForgotPasswordComponent,
    IosSafariBrowserNotSupportedComponent,
    PasswordResetComponent,
    RegisterComponent
    ],
  imports: [
    MatFormFieldModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    HttpClientModule,
    MatCardModule,
    AngularFireModule.initializeApp(environment.firebase, { automaticDataCollectionEnabled: false }),
    AngularFireAnalyticsModule,
    MatInputModule,
    FormsModule,
    VirtualScrollerModule,
    MatSnackBarModule,
    SharedModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    ObserversModule,
    MatCheckboxModule,
    NgxSliderModule,
    MatSelectModule,
    MatChipsModule,
    NgOptimizedImage,
    MatDialogModule,
    MatTooltipModule,
    NgxChartsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (translationService: TranslationService) => {
          return new TranslationsLoader(translationService)
        },
        deps: [TranslationService]
      }
    }),
    MatCardModule,
    AngularFireModule.initializeApp(environment.firebase, {automaticDataCollectionEnabled: false}),
    AngularFireAnalyticsModule,
    FormsModule,
    VirtualScrollerModule,
    MatSnackBarModule,
    SharedModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    ObserversModule,
    MatCheckboxModule,
    NgxSliderModule,
    MatSelectModule,
    MatChipsModule,
    NgOptimizedImage,
    MatDialogModule,
    MatTooltipModule,
    NgxChartsModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [
    Redirect,
    
    {
      // processes all errors
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
   {
      provide: APP_INITIALIZER,
      useFactory: (initializationService: InitializationService) => () =>  
 
        initializationService.initializeApp()

      ,
      deps: [InitializationService],
      multi: true
    },
    ScreenTrackingService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
