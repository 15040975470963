<div class="content-container">
    <h2>{{'login.error.header.title' | translate}}</h2>

    <h3>{{'login.error.header.subtitle' | translate}}</h3>
</div>

<div class="content-container">
    <button mat-button (click)="onToLoginClick()" expand="block" class="option-button">
        {{'login.error.to-login.text' | translate}}
    </button>
    <button mat-button (click)="onBackToAppClick()" expand="block" class="option-button">
        {{'login.error.back-to-app.text' | translate}}
    </button>
</div>