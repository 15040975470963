import {Injectable} from '@angular/core';
import {IconsService} from './icons.service';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class InitializationService {

  constructor(private iconsService: IconsService,
              private translateService: TranslateService) {
  }

  public async initializeApp() {
    this.translateService.setDefaultLang(environment.DEFAULT_LANG);
    this.translateService.use(environment.DEFAULT_LANG)
    this.iconsService.registerIcons();
    //await this.grocerService.loadEnabledGrocers();
  }
}
