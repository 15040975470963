import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AppleAuthorizationService {

    BASE_PATH = '/authorization/oauth/apple';

    constructor(private http: HttpClient) {
    }


    getAuthUrl(loginChallenge: string): Observable<string> {
        return this.http.get(`${environment.API_URL}${this.BASE_PATH}/auth-url?loginChallenge=${loginChallenge}`, {responseType: 'text'});
    }
}
