import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { environment } from '@env/environment';
import { LoginAccepted } from '@app/model/model';

@Injectable({
    providedIn: 'root'
})
export class TwitterAuthorizationService {

    BASE_PATH = '/authorization/oauth/twitter';

    constructor(private http: HttpClient) {
    }


    getAuthUrl(): Observable<string> {
        return this.http.get(`${environment.API_URL}${this.BASE_PATH}/auth-url`, {responseType: 'text', withCredentials: true});
    }

    login(oauthToken: string, oauthVerifier: string, loginChallenge: string): Observable<LoginAccepted> {
        return this.http.post<LoginAccepted>(`${environment.API_URL}${this.BASE_PATH}/login`, {
                oauthToken,
                oauthVerifier,
                loginChallenge
            },
            {withCredentials: true});
    }
}
