import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiError } from '@app/model/error/apiError';
import { ErrorCodes } from '@app/model/error/errorCodes';
import { AuthorizationService } from '@app/services/api/authorization.service';
import { UserManagementService } from '@app/services/api/user-management.service';
import { MustMatch } from '@app/validators/must-match.validator';
import { TranslateService } from '@ngx-translate/core';
import { RecaptchaComponent } from 'ng-recaptcha';
import { DeviceDetectorService } from 'ngx-device-detector';
import { switchMap, take } from 'rxjs';
import { Location } from '@angular/common';
import {MatSnackBar} from "@angular/material/snack-bar";


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  @ViewChild('recaptchaComponent', { static: true }) recaptchaComponent: RecaptchaComponent;
  private challenge: string;
  private captchaPassed = false;
  private captchaResponse: string;
  registerForm: FormGroup;
  passwordMinLength = 8;
  passwordMinLengthTranslateParam = { value: this.passwordMinLength };

  loading: boolean = false;

  constructor(private route: ActivatedRoute,
    private userManagementService: UserManagementService,
    private authorizationService: AuthorizationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private location: Location,
    private translate: TranslateService, 
    private snackBar: MatSnackBar,) {
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.email
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(this.passwordMinLength),
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).+$')
      ])),
      dataProtectionRegulations: new FormControl(false, Validators.pattern('true')),
      passwordRepeat: new FormControl('', Validators.required),
      recaptcha: new FormControl(null, Validators.required)
    }, {
      validator: MustMatch('password', 'passwordRepeat')
    });

    this.route.queryParamMap.subscribe(queryParams => {
      this.challenge = queryParams.get('login_challenge');
      // a login challenge is required
      if (this.challenge == null) {
        // generate a new one!
        this.challenge = window.localStorage.getItem('loginChallenge');
      } else {
        window.localStorage.setItem('loginChallenge', this.challenge);
      }
      if (this.deviceService.os === 'iOS') {
        // 'oauth2_authentication_csrf' cookie gets lost on switch from safari web view to safari native browser ->
        // login not possible without this cookie -> not supported by now!
        // to be able to detect this browser switch clear get parameters including login_challenge
        // on switch this.challenge is null/empty then!
        this.location.replaceState('/login', '');
        if (!this.challenge) {
          window.location.href = 'ios-safari-browser-not-supported';
        }
      }
    });

  }

  register(form: FormGroup) {
    this.loading = true;
    this.userManagementService.register(form.value.email, form.value.password, this.captchaResponse)
      .pipe(
        take(1),
        switchMap(userId => {
          return this.authorizationService.acceptLoginRequest(this.challenge, userId);
        })).subscribe(loginAccepted => {
          window.location.href = loginAccepted.redirectUrl;
        }, (error: HttpErrorResponse) => {
          this.loading = false;
          this.recaptchaComponent.reset();
          if (error.error) {
            const apiError: ApiError = JSON.parse(error.error);
            if (apiError.errorCode === ErrorCodes.EMAIL_ALREADY_REGISTERED) {

              this.snackBar.open(this.translate.instant('login.register.error.email-already-registered'), null, { panelClass: 'snackbar-error' });

              return;
            } else if (apiError.errorCode === ErrorCodes.RECAPTCHA_VALIDATION_FAILED) {

              this.snackBar.open(this.translate.instant('login.register.error.recaptcha-validation-failed'), null, { panelClass: 'snackbar-error' });

              return;
            }
          }
          this.router.navigate(['error'], { state: { error: error.message } });
        });

  }

  captchaResolved(response: string) {
    this.captchaPassed = true;
    this.captchaResponse = response;
  }
}
