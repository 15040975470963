import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {media} from "@app/util/media";
import {Observable, Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
//import { AuthService } from '@app/services/api/auth.service';
//import { LoginComponent } from '@app/modals/login/login.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];

  sm$ = media(`(max-width: 767px)`);

  isAuthenticated$: Observable<boolean>;

  //@ViewChild(TopSearchBarComponent, {static: true}) private searchBarComponent: TopSearchBarComponent;

  mobileSearchMode = false;
  constructor(private dialog: MatDialog) {
    //this.isAuthenticated$ = authService.isAuthenticated$;
  }

  ngOnInit(): void {
    this.subscriptions.push(this.sm$.subscribe(small => {
      if (!small) {
        this.mobileSearchMode = false;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onSearchIconClick() {
    this.mobileSearchMode = true;
    //this.searchBarComponent.focus();
  }

  onSearchBackClick() {
    this.mobileSearchMode = false;
  }

  onSubscriptionIconClick() {
    //this.dialog.open(SubscribeComponent);
  }

  onLoginClick() {
   // this.dialog.open(LoginComponent);
    //this.authService.login();
  }

  onLogoutClick() {
   // this.authService.logout();
  }
}
