import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {filter, tap} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-layout-header-footer',
  templateUrl: './layout-header-footer.component.html',
  styleUrls: ['./layout-header-footer.component.scss']
})
export class LayoutHeaderFooterComponent implements OnInit, OnDestroy {

  constructor(router: Router) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

}
