import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from '@env/environment';
import { LoginAccepted } from '@app/model/model';

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthorizationService {

  BASE_PATH = '/authorization/oauth/google';

  constructor(private http: HttpClient) {
  }


  getAuthUrl(): Observable<string> {
    return this.http.get(`${environment.API_URL}${this.BASE_PATH}/auth-url`, {responseType: 'text'});
  }

  login(authCode: string, loginChallenge: string): Observable<LoginAccepted> {
    return this.http.post<LoginAccepted>(`${environment.API_URL}${this.BASE_PATH}/login`, {authCode, loginChallenge});
  }
}
